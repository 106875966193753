<template>
  <div id="ContactForm">
    <div class="columns is-multiline">
      <!-- First Name + Last Name -->
      <div class="column is-12">
        <div class="columns is-mobile">
          <div class="column">
            <b-field>
              <b-input
                required
                expanded
                v-model="firstName"
                :placeholder="$t('form.firstname')"
              />
            </b-field>
          </div>
          <div class="column">
            <b-field>
              <b-input
                required
                expanded
                v-model="lastName"
                :placeholder="$t('form.lastname')"
              />
            </b-field>
          </div>
        </div>
      </div>
      <!-- Email + Phone NO. -->
      <div class="column is-12">
        <div class="columns is-mobile">
          <div class="column">
            <b-field>
              <b-input
                required
                expanded
                v-model="email"
                :placeholder="$t('form.email')"
              />
            </b-field>
          </div>
          <div class="column">
            <b-field>
              <b-input
                required
                expanded
                v-model="contactNumber"
                :placeholder="$t('form.phone')"
              />
            </b-field>
          </div>
        </div>
      </div>
      <!-- Description -->
      <div class="column is-12" style="padding-bottom: 0;">
        <b-field>
          <b-input
            expanded
            required
            maxlength="400"
            type="textarea"
            v-model="description"
            :placeholder="$t('form.description')"
          />
        </b-field>
      </div>
      <!-- Attachment -->
      <div
        class="column is-12"
        v-if="from_view === 'special'"
        style="padding-top: 0;"
      >
        <b-field class="file">
          <b-upload v-model="file" class="file-label">
            <span class="file-cta">
              <b-icon class="file-icon" icon="paperclip"></b-icon>
              <span class="file-label">{{ $t("form.attachment") }}</span>
            </span>
            <span class="file-name" v-if="file">
              {{ file.name }}
            </span>
          </b-upload>
        </b-field>
      </div>
      <!-- Submit BTN -->
      <div class="column is-12">
        <div class="columns is-mobile">
          <div class="column is-offset-8 has-text-right">
            <b-field>
              <b-button
                type="is-primary"
                :disabled="isDisableNextBtn"
                @click="validateForm"
              >
                {{ $t("btn.submit") }}
              </b-button>
            </b-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MailApiManager from "@/api/MailApiManager";

export default {
  name: "ContactForm",
  props: ["from_view"],
  data() {
    return {
      firstName: null,
      lastName: null,
      email: null,
      description: null,
      contactNumber: null,
      file: null
    };
  },
  computed: {
    isDisableNextBtn: function() {
      if (
        this.firstName &&
        this.lastName &&
        this.email &&
        this.contactNumber &&
        this.description
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    validateForm: function() {
      if (!this.isDisableNextBtn) {
        const fd = new FormData();
        fd.append("reference", this.from_view);
        fd.append("firstname", this.firstName);
        fd.append("lastname", this.lastName);
        fd.append("email", this.email);
        fd.append("phone", this.contactNumber);
        fd.append("description", this.description);
        if (this.file) {
          fd.append("file", this.file, this.file.name);
        }
        this.submitRequestEmail(fd);
      } else {
        this.$buefy.snackbar.open({
          duration: 5000,
          message: "Please completed every <b>required field</b> in the form.",
          type: "is-danger",
          actionText: "OK",
          queue: true
        });
      }
    },
    submitRequestEmail: async function(params) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await MailApiManager.requestSubmitEmail(params);
      loadingComponent.close();
      switch (response.code) {
        case "0":
          this.$buefy.snackbar.open("Your request was sent!");
          break;

        default:
          this.$buefy.snackbar.open({
            message:
              "Unfortunately cannot submit your request please try again",
            type: "is-danger",
            actionText: "OK",
            queue: true
          });
          break;
      }
    }
  }
};
</script>
