import MainConnection from "./MainConnection";

export default {
  requestSubmitEmail: async function(params) {
    try {
      const requestURL = "/contact/emailWithAttach";
      const response = await MainConnection.requestFile().post(
        requestURL,
        params
      );
      return response.data;
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  }
};
