<template>
  <div id="About">
    <section class="hero is-medium is-primary is-bold">
      <div
        class="hero-body center-cover-bg-image"
        :style="
          'background-image: linear-gradient(rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40)), url(covers/about-cover.jpg);'
        "
      >
        <div class="container">
          <h1 class="title">{{ $t("about.title") }}</h1>
          <h2 class="subtitle">
            {{ $t("about.subtitle") }}
          </h2>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="columns is-variable is-centered is-multiline is-12">
          <div class="column is-4">
            <figure class="image is-4by3">
              <img src="../assets/bos_logo.svg" />
            </figure>
          </div>
          <div class="column is-5 is-offset-1">
            <p class="subtitle is-6" style="text-align: left;">
              {{ $t("about.content") }}
            </p>

            <p class="subtitle is-6" style="text-align: left;">
              <b>Contact Us</b> <br /><br />
              BLUSHING OWL STUDIO CO.,LTD<br />
              18/93 Soi Khuborn27 Khuborn Rd. Tharaeng Bangkaen Bangkok Thailand
              Post code 10220<br />
              Email : admin@blushingowlstudio.com<br />
              Phone : (+66)84-347-0099<br /><br />
              Facebook : BlushingOwlStudio<br />
              Instagram : BlushingOwlStudio<br />
              Line ID : BlushingOwlStudio<br />
              WeChat ID : BlushingOwlStudio<br />
              Weibo ID : BlushingOwlStudio<br />
            </p>

            <contact-form :from_view="'contact'" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import ContactForm from "@/components/forms/ContactForm.vue";

export default {
  name: "About",
  components: {
    ContactForm
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>
